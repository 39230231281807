.error-page {
	padding: 5rem 0;

	span.badge {
		margin-bottom: 2.5rem;
		padding: 0.375rem 1rem 0.25rem 1rem;
		border-radius: 2rem;
		box-shadow: 0 0 0 0.0625rem rgba(var(--accent), 0.2);

		line-height: 1rem;
		font-size: 1rem;
		color: rgba(var(--accent), 1);
	}

	h1 {
		font-size: 3.5rem;
	}

	&.four {
		h1 {
			margin-bottom: 3rem;
		}
	}

	&.five {
		h1 {
			margin: 2rem 0 1rem;
		}
	}
}