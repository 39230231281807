:root {
    font-size: 16px;
}

// Packages
@import '../../node_modules/swiper/swiper';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Base styles
@import 'reset';
@import 'variables';
@import 'breakpoints';
@import 'typography';
@import 'cols';

@import 'ui/elements';
@import 'ui/native';
@import 'ui/buttons';
@import 'ui/blocks';
@import 'ui/forms';
@import 'ui/scrollbar';

@import 'components/header';
@import 'components/logo';
@import 'components/menu';
@import 'components/language-switch';
@import 'components/poi';
@import 'components/footer';
@import 'components/search';
@import 'components/page-header';
@import 'components/tags';
@import 'components/socials';
@import 'components/tips';
@import 'components/breadcrumbs';
@import 'components/pagination';
@import 'components/filters';
@import 'components/active-filters';
@import 'components/list-heading';
@import 'components/cookies';
@import 'components/placeholder';

@import 'blocks/activity-list';
@import 'blocks/point-list';
@import 'blocks/testimonials';
@import 'blocks/c-slider';
@import 'blocks/complex-gallery';
@import 'blocks/gallery';
@import 'blocks/partners';
@import 'blocks/star-rating';
@import 'blocks/projects-slider';
@import 'blocks/progress';
@import 'blocks/services';
@import 'blocks/call-to-action';
@import 'blocks/content-section';
@import 'blocks/our-methods';
@import 'blocks/project-item';
@import 'blocks/vacancy-item';
@import 'blocks/catalog';
@import 'blocks/apply';

@import 'page/vacancy';
@import 'page/form';
@import 'page/service';
@import 'page/error-page';



//  TEMP
.niekam {
    display: inline-flex;
    margin-bottom: 2.5rem;
    line-height: 4rem;
    font-weight: 900;
    font-size: 4rem;
    color: rgba(var(--accent), 1);
    // color: white;

    &-form {
        margin: 1rem 0;
        padding: 2rem 1.5rem !important;
        box-sizing: border-box;
        box-shadow: 0 0 0 0.125rem rgba(var(--dark), 0.08);
        border-radius: 1rem;
    }
}