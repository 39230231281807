.poi {
	&-frame {
		position: relative;

		img {
			width: 100%;
			height: auto;
			border-radius: 0.625rem;
		}
	}

	&-wrap {
		position: absolute;
		pointer-events: none;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 30;
	}

	&-fixture {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		pointer-events: all;

		width: 0;
		height: 0;

		position: absolute;
		z-index: 1;

		&.active {
			z-index: 9;

			.poi {
				&-icon {
					color: rgba(var(--white), 1);

					&:before {
						background: $gradient;
					}

					&:after {
						opacity: 0;
					}

					.bi {
						position: relative;
						z-index: 10;
					}
				}

				&-content {
					opacity: 1;
					pointer-events: all;

					@include breakpoint(sm) {
						transform: translateY(0);
					}
				}
			}
		}
	}

	&-content {
		padding: 1rem 1.5rem 0.5rem 1rem;
		border-radius: 0.625rem;
		background: rgba(var(--white), 1);
		box-shadow: $shadow;
		pointer-events: none;
		opacity: 0;
		position: absolute;
		transition: $transition-faster;

		@include breakpoint(sm-down) {
			width: 100%;
			border-radius: 0.625rem 0.625rem 0 0;
			transform: none;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;

			padding: 2rem 5rem 2rem 2rem;
		}

		@include breakpoint(sm) {
			min-width: 22.5rem;
			top: 2.5rem;
			transform: translateY(2rem);
		}

		.close {
			padding: 1.75rem;
			cursor: pointer;
			position: absolute;
			top: 0;
			right: 0;

			@include breakpoint(sm) {
				display: none;
			}
		}

		.title {
			margin-bottom: 0.5rem;
			font-size: 1rem;
		}

		p {
			margin: 0;
			line-height: 130%;
			font-size: 1rem;
		}
	}

	&-icon {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 2.5rem;
		color: rgba(var(--accent), 1);
		position: relative;

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			position: absolute;
			animation: load 1.4s cubic-bezier(0.22, 1, 0.36, 1) infinite;
			z-index: 0;
		}

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			border-radius: 100%;
			// background: $gradient;
			z-index: 1;
			background: rgba(var(--white), 1);
			box-shadow: $shadow;
			transition: $transition-faster;
		}

		.bi {
			position: relative;
			z-index: 10;
		}

		&-wrap {
			padding: 1rem;
			cursor: pointer;

			&:hover {
				.poi-icon {
					color: rgba(var(--white), 1);

					&:before {
						background: $gradient;
					}
				}
			}
		}
	}
}

@keyframes load {
	0% {
		box-shadow: 0 0 0 0 rgba(var(--dark), 0.24);
	}
	100% {
		box-shadow: 0 0 0 1rem rgba(var(--dark), 0);
	}
}