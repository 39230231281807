.partners {
	&-wrapper {
		.swiper {
			&-wrapper {
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: stretch;
			}

			&-slide {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				height: 3.5rem;

				img {
					opacity: 0.48;
					transition: $transition-fast;
				}

				&:hover {
					img {
						opacity: 1;
					}
				}
			}
		}
	}
}