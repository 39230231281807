.block {
	&-simple {
		// overflow: hidden;

		.buttons {
			margin-top: 1rem;
		}
	}

	&-call_to_action {
		& + footer {
			margin-top: 0
		}
	}


	&-partner_slider {
		& + .block-project_slider .projects-slider-wrapper {
			padding-top: 2rem;
		}
	}
}

.sub-block {
	&-afbeelding_gallerij {
		width: calc(100% + 3rem);
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        @include breakpoint(sm-down) {
			width: calc(100% + 1rem);
	        margin-left: -0.5rem;
	        margin-right: -0.5rem;
        }
	}

	&-left {
		&.sub-block-pagina_inhoud {
			@include breakpoint(sm) {
				padding-right: 4rem;
			}
		}
	}

	&-right {
		&.sub-block-pagina_inhoud {
			@include breakpoint(sm) {
				padding-left: 4rem;
			}
		}

		&.sub-block-knoppen {
			@include breakpoint(sm) {
				padding-left: 4rem;
			}
		}
	}

	&-beoordeling {
		@include breakpoint(sm) {
			padding: 2rem 0;
		}
	}

	&-pagina_inhoud {
		& + .sub-block-knoppen {
			margin-top: 1.5rem;
		}
	}
}