.cookie {
	&-fixture {
		position: fixed;
		z-index: 999999; // top level
		bottom: 0
	}

	&-wrapper {
		margin: 1rem;

		@include breakpoint(sm-down) {
			margin: 1rem 0.75rem;
		}
	}

	&-container {
		--flap-color: var(--teal);
		max-height: 80vh;
		overflow: auto;
		max-width: 34rem;
		padding: 2rem;
		background: rgba(var(--white), 1);
		color: rgba(var(--dark), 1);
		border-radius: 0.625rem;
		position: relative;
		box-shadow: 0 0 0 0.125rem rgba(var(--dark), 0.32);

		@include breakpoint(sm-down) {
			padding: 1.25rem 1rem 4rem;
		}

		.intro {
			padding-right: 4rem;

			h1 {
				margin-bottom: 0.5rem;
				font-size: 1.375rem;
				font-family: "Nomenclatur-Medium";
				letter-spacing: 0;
			}

			p {
				font-size: 1.125rem;
			}
		}

		.button {
			&.close {
				max-height: unset;
				padding: 2rem;
				color: inherit;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 10;
				transition: $transition-faster;

				&:before {
					width: 3rem;
					height: 3rem;
					border-radius: 4rem;
					top: unset;
					left: unset;
					right: unset;
					bottom: unset;
					z-index: 0;
				}

				&:hover {
					&:before {
						background: rgba(var(--white), 0.12);
					}
				}
			}

			&.primary {
				background: rgba(var(--teal), 0.48);
				color: rgba(var(--dark), 1);
				font-size: 1.125rem;

				@include breakpoint(sm-down) {
					background: rgba(var(--teal), 1);
				}

				&:hover {
					background: rgba(var(--dark), 1);
					color: rgba(var(--teal), 1);
				}
			}

			&s {
				justify-content: flex-end;
				--bg: var(--white);
				--accent: var(--neon);

				@include breakpoint(sm-down) {
					width: calc(100% - 2rem);
					margin: 0 1rem 1rem;
					padding: 2rem 1rem 1rem;
					background: linear-gradient(to bottom, rgba(var(--bg), 0) 0%, rgba(var(--bg), 1) 100%);
					border-radius: 0.625rem;
					position: fixed;
					left: 0;
					bottom: 0;
					pointer-events: none;

					.button {
						pointer-events: all;
					}
				}
			}
		}
	}

	&-options {
		margin: 0.75rem 0 1.5rem;

		li {
			width: calc(100% + 1.5rem);
			margin-left: -0.75rem;
			padding: 0.75rem;
			padding-left: 3.5rem;
			cursor: pointer;
			position: relative;
			transition: $transition-faster;

			&.disabled {
				opacity: 0.56;
				pointer-events: none;
			}

			&:hover {
				border-radius: 0.625rem;
				background: rgba(var(--white), 0.4);
			}

			.input-checkbox {
				position: absolute;
				left: 0.75rem;
			}

			span.title {
				margin-bottom: 0.25rem;
				font-family: "Nomenclatur-Medium";

				small {
					margin-left: 0.5rem;
					color: rgba(var(--glow), 0.64);
				}
			}

			p {
				margin: 0;
				font-size: 1.125rem;
			}
		}
	}
}