.menu {
	&-wrapper {
		width: 100%;
		position: sticky;
		top: 0;
		z-index: 99999;
	}

	&-container {
		@include breakpoint(sm-down) {
			width: 100vw !important;
			margin: 0 -0.25rem;
			padding: 1rem 0.5rem !important;
		}

		@include breakpoint(sm) {
			padding-top: 2.5rem;
			padding-bottom: 1rem;
		}
	}

	&-content {
		@include breakpoint(md-down) {
			display: none;
		}
	}

	&-actions {
		.button {
			@include breakpoint(sm) {
				padding: 0 1.5rem;
				border-radius: 10rem;

				&:before,
				&:after {
					border-radius: 10rem;
				}
			}
		}
	}

	&-item {
		padding: 1rem 0.75rem;
		line-height: 1rem;
		font-size: 1rem;
	    font-family: 'Nomenclatur-Medium';
	    font-weight: normal;
	    font-style: normal;
		cursor: pointer;
		transition: $transition-faster;

		&.children {
			span {
				margin-right: 0.5rem;
			}
		}

		svg {
			color: rgba(var(--teal), 1);
			transform: rotate(90deg);
			transition: transform $transition-faster;
		}

		&:hover {
			color: rgba(var(--teal), 1.0);

			svg {
				color: rgba(var(--teal), 1.0);
			}
		}

		&.active {
			color: rgba(var(--accent), 1.0);

			svg {
				color: rgba(var(--accent), 1.0);
				transform: rotate(270deg);
			}
		}
	}
}

.submenu {
	&-fixture {
		width: 100%;
		position: relative;
	}

	&-wrapper {
		width: 100%;
		height: 0;
		padding: 0 0.5rem;
		background: rgba(var(--white), 0.96);
		backdrop-filter: blur(0.25rem);
		border-radius: 0 0 0.625rem 0.625rem;
		overflow: hidden;
		box-shadow: $shadow;
		position: absolute;
		top: 100%;

		@include breakpoint(sm) {
			width: calc(100% + 6rem);
			margin: 0 -3rem;
			padding: 0 2.5rem;
			border-radius: 0.625rem;
		}

		ul {
			padding-top: 0;
			padding-bottom: 1rem;

			@include breakpoint(sm) {
				padding-top: 1.5rem;
				padding-bottom: 0.75rem;
			}

			&.services {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;

				@include breakpoint(sm) {
					flex-flow: row nowrap;
					align-items: stretch;	
					margin-left: -0.5rem;
					margin-right: -0.5rem;
				}

				li {
					@include breakpoint(sm) {
						padding: 0 0.5rem;
						flex-grow: 2;
					}

					@include breakpoint(sm-down) {
						width: calc(50% - 0.125rem);

						svg {
							display: none;
						}

						.inner {
							font-size: 1.125rem;

							span {
								width: 100%;
								text-align: center;
							}
						}

						&:last-child {
							width: 100%;
						}
					}
				}
			}

			&.activities {
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;
				width: calc(100% + 0.5rem);
				margin: 0 -0.25rem;

				@include breakpoint(sm) {
					width: calc(100% + 1rem);
					margin: 0 -0.5rem;
				}

				li {
					flex-shrink: 0;
					width: 50%;
					padding: 0 0.125rem;

					@include breakpoint(sm) {
						width: calc(100% / 3);
						padding: 0 0.375rem;
					}

					@include breakpoint(md) {
						width: 25%;
						padding: 0 0.5rem;
					}
				}
			}

			li {
				margin-bottom: 0.25rem;
				break-inside: avoid-column;

				@include breakpoint(sm) {
					margin-bottom: 0.75rem;
				}

				a {
					width: 100%;
					box-sizing: border-box;
					position: relative;
					overflow: hidden;

					&._highlight,
					&:hover {
						&:after {
							opacity: 1;
							background: $gradient;
							transform: scale(1);
						}

						.inner {
							color: rgba(var(--white), 1);

							svg {
								color: inherit;
							}
						}

						figure {
							&.image {
								img {
									// opacity: 0.8;
								}
							}
						}
					}

					&:before,
					&:after {
						content: '';
						width: 100%;
						height: 100%;
						border-radius: 0.625rem;
						position: absolute;
						top: 0;
						left: 0;
					}

					&:before {
						background: rgba(var(--light), 1);
					}

					&:after {
						opacity: 0;
						background: $gradient-before;
						transition: $transition-fastest;
						transform: scale(0.96);
						z-index: 1;
					}

					figure.icon {
						flex-grow: 2;
						width: 100%;
						height: auto;
						padding-top: 1rem;
						position: relative;
						z-index: 2;

						img {
							width: auto;
							height: auto;
							max-width: 8rem;
							top: 0;
						}
					}

					figure.image {
						width: 100%;
						height: 100%;
						position: absolute;
						z-index: 2;
						border-radius: 0.625rem;

						img {
							width: auto;
							height: auto;
							top: 0;
							opacity: 0.16;
							mix-blend-mode: multiply;
						}

						& + .inner {
							height: 8rem;
							align-items: flex-end;
						}
					}

					.inner {
						flex-shrink: 0;
						// height: 4rem;
						padding: 1rem 1.5rem;
						border-radius: 0.625rem;
						font-size: 1.125rem;
						font-weight: 800;
						position: relative;
						z-index: 3;

						span {

						}

						svg {
							color: rgba(var(--teal), 1);
						}
					}
				}
			}
		}
	}
}