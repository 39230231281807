.list-heading {
	padding: 0;
	box-sizing: border-box;
	cursor: pointer;

	@include breakpoint(sm) {
		padding: 0 2rem;

		display: flex;
		flex-flow: row nowrap;
	}

	&-wrap {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
		margin-bottom: 1rem;

		@include breakpoint(sm) {
			flex-flow: row nowrap;
			justify-content: space-between;
			margin-bottom: 0;
		}

		.input-wrap {
			position: relative;

			@include breakpoint(sm-down) {
				padding: 0 0.75rem;
			}

			input {
				width: auto;
				border-radius: 2rem;
				padding-left: 1.5rem;
				padding-right: 3rem;

				@include breakpoint(sm-down) {
					width: 100%;
				}

				@include breakpoint(lg) {
					min-width: 30rem;
				}
			}

			.bi {
				position: absolute;
				pointer-events: none;
				right: 2.5rem;
			}
		}
	}

	&.active {
		i.toggle {
			transform: rotate(90deg);
		}
	}

	.inner {
		width: 100%;
		padding: 1.5rem 1rem;
		box-sizing: border-box;

		@include breakpoint(sm) {
			padding: 2rem 0;
		}
	}

	i.toggle {
		color: rgba(var(--teal), 1);
	}

	h3 {
		white-space: nowrap;
		margin: 0 0.75rem -0.5rem;

		@include breakpoint(sm) {
			// margin: 0 1.5rem -0.75rem;
			margin: 0 1.5rem
		}
	}

	.count {
		height: 1.75rem;
		min-width: 1.75rem;
		background: rgba(var(--accent), 1);
		border-radius: 1rem;
		font-size: 1rem;
		color: rgba(var(--white), 1);

		span {
			padding: 0.125rem 0.5rem 0;
			color: inherit;
		}
	}
}