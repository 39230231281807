.testimonials {
	&-cols {
		display: flex;
		flex-flow: column nowrap;

		@include breakpoint(sm) {
			flex-flow: row nowrap;
		}
	}

	&-content {
		display: flex;
		flex-flow: column-reverse nowrap;

		@include breakpoint(sm) {
			flex-flow: row nowrap;
			align-items: flex-end;
			justify-content: flex-end;
			position: absolute;
		}
	}

	.quote {
		@include breakpoint(sm) {
			right: 3rem;
		}

		@include breakpoint(sm-down) {
			top: -2rem
		}

		.inner {
			padding: 4rem 5rem 4rem 3rem;
			box-sizing: border-box;

			@include breakpoint(sm-down) {
				padding: 2rem 1.25rem;
			}
		}

		small {
			display: inline-flex;
			padding: 0.5rem 2.5rem;
			box-sizing: border-box;
			position: absolute;
			top: 100%;

			a {
				display: inline;
			}
		}

		blockquote {
			margin-bottom: 3rem;

				@include breakpoint(sm-down) {
					margin-bottom: 2rem
				}

			h2 {
				margin: 0;
				line-height: 128%;
				font-size: 2.25rem; 
				font-family: "Nomenclatur-Medium";

				@include breakpoint(sm-down) {
					font-size: 1.5rem;
					letter-spacing: -1px;
				}
			}
		}
	}

	.avatar {
		figure {
			flex-shrink: 0;
			width: 4rem;
			height: 4rem;
			border-radius: 100%;
			box-shadow: 0 0 0 0.25rem rgba(var(--white), 1);
			background: rgba(var(--white), 1);
			overflow: hidden;
		}

		.content {
			padding: 0 0 0 1.25rem;

			.name {
				font-family: "Nomenclatur-Medium";
				font-size: 1.25rem;
			}

			.location {
				font-size: 1rem;
				opacity: 0.8;
			}
		}
	}

	&.small {
		max-width: 100%;
		margin-top: 2rem;
		padding: 1.5rem 1.5rem 1rem;
		box-sizing: border-box;
		border-radius: 1.5rem;
		box-shadow: inset 0 0 0 0.0625rem rgba(var(--teal), 0.24);

		@include breakpoint(sm) {
			margin-left: -1rem;
			padding: 3.5rem 4rem 2.5rem;
		}

		blockquote {
			margin-bottom: 1.75rem;
			padding: 0 0.5rem;
			box-sizing: border-box;
			position: relative;

			@include breakpoint(sm-down) {
				margin-bottom: 1.25rem;
			}

			&:before {
				content: '';
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				background-image: url('data:image/svg+xml,<svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.361 0.895996V4.25084C9.10351 4.55191 8.0195 5.17557 7.10893 6.1218C6.19836 7.02503 5.74308 7.92826 5.74308 8.83148C5.74308 9.39062 6.17668 9.67019 7.04389 9.67019C8.30134 9.67019 9.32031 10.1218 10.1008 11.025C10.8813 11.8852 11.2715 12.982 11.2715 14.3154C11.2715 15.6057 10.8162 16.7024 9.90568 17.6057C9.03847 18.4659 7.86774 18.896 6.39348 18.896C4.78915 18.896 3.44497 18.3799 2.36096 17.3476C1.32031 16.3154 0.799988 14.7885 0.799988 12.767C0.799988 9.58417 1.75392 6.9175 3.66178 4.76696C5.613 2.57342 7.84606 1.28309 10.361 0.895996ZM23.9545 0.895996V4.25084C22.697 4.55191 21.613 5.17557 20.7024 6.1218C19.7919 7.02503 19.3366 7.92826 19.3366 8.83148C19.3366 9.39062 19.7702 9.67019 20.6374 9.67019C21.8948 9.67019 22.8921 10.1218 23.6293 11.025C24.4097 11.8852 24.8 12.982 24.8 14.3154C24.8 15.6057 24.3664 16.7024 23.4992 17.6057C22.632 18.4659 21.4396 18.896 19.9219 18.896C18.3176 18.896 16.9951 18.4014 15.9545 17.4121C14.9138 16.3799 14.3935 14.8315 14.3935 12.767C14.3935 9.54116 15.3474 6.85299 17.2553 4.70245C19.1631 2.55191 21.3962 1.28309 23.9545 0.895996Z" fill="%23007682"/></svg>');
				background-repeat: no-repeat;
				background-size: 1.5rem auto;
				bottom: 100%;
				right: calc(100% + 0.5rem);

				@include breakpoint(sm-down) {
					right: calc(100% - 0.5rem);
					bottom: calc(100% + 0.5rem);
				}
			}

			h3 {
				margin: 0;
				line-height: 140%;
				font-size: 1.5rem;
			}
		}

		.avatar {
			transform: translateX(-0.5rem);
		}
	}
}