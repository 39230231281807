.tip {
	margin: 0 !important;
	padding: 0.375rem 0.25rem 0.25rem;
	background: rgba(var(--dark), 1);
	border-radius: 0.125rem;
	opacity: 0;
	font-size: 0.875rem !important;
    font-family: "Nomenclatur";
    font-weight: 100;
	color: rgba(var(--white), 1) !important;
	pointer-events: none;
	position: absolute;
	top: 100%;
	transform: translateY(1rem);
	transition: $transition-fastest;
	transition-delay: 0;

	&-wrap {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		position: relative;

		&:hover {
			.tip {
				opacity: 1;
				transform: translateY(0.5rem);
				transition-delay: 1s;
			}
		}
	}
}