.call-to-action {
	position: relative;
	background: $gradient;

	@include breakpoint(sm-down) {
		padding: 0 0 2.5rem;

		background: rgba(var(--accent), 1);
	}

	@include breakpoint(sm) {
		margin-top: 4rem;
	}

	.gallery {
		@include breakpoint(sm) {
			padding-right: 4rem;
		}

		@include breakpoint(sm-down) {
			width: 100vw;
			margin: 0 -0.25rem;
			position: relative;

			img {
				border-radius: 0;
			}

			&:before {
				content: '';
				width: 100%;
				height: 100%;

				pointer-events: none;

				background: linear-gradient(to bottom, rgba(var(--accent), 0) 0%, rgba(var(--accent), 1) 100%);
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 999;
			}
		}
	}

	.inner {
		h2 {
			// font-size: 3rem
		}

		@include breakpoint(sm) {
			// padding-left: 2rem;
		}
	}
}