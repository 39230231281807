.page-header {
    position: relative;
    z-index: 999;
    overflow-x: clip; 
    overflow-y: visible;

    h1, h2, h3, h4 {
        &.title {
            margin: 0;
            font-size: 2.25rem;

            @include breakpoint(sm-down) {
                margin-bottom: 1rem;
            }

            @include breakpoint(sm) {
                font-size: 3.5rem;
            }

            &.sub {
                font-size: 1.75rem;

                @include breakpoint(sm) {
                    font-size: 3rem;
                }
            }

            b {
                @extend .w500;
            }
        }
    }

    .inner {
        padding-left: 1rem;
        padding-right: 2.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include breakpoint (sm) {
            padding: 2.5rem;
        }

        @include breakpoint (md) {
            padding-left: 4rem;
            padding-right: 5rem;
        }

        .title {
            margin-bottom: 1rem;
        }

        .content {
            @include breakpoint(sm-down) {
                padding-right: 1.5rem;
            }

            @include breakpoint (sm) {
                padding-left: 2.5rem;
            }

            .buttons {
                margin-top: 0.5rem;
            }
        }
    }

    &.home {
        padding: $sp 0 2rem;

        @include breakpoint(sm) {
            padding: 8rem 0 calc($bp/2);
        }

        .content {
            @include breakpoint (sm) {
                padding-right: 4rem;
            }
        }

        .title {
            // @include breakpoint(md-down) { 
                margin-bottom: 1rem;
            // }
        }
    }

    &.service {
        h1, h2, h3 {
            &.title {
                margin-bottom: 2rem;
            }
        }

        .buttons {
            margin-top: 1rem;
        }

        .cols {
            @include breakpoint(sm-down) {
                flex-flow: column-reverse nowrap;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &.vacancy {
        @include breakpoint(sm) {
            padding-top: 2.5rem;
        }

        @include breakpoint(sm-down) {
            .trail a {
                color: rgba(var(--white), 1) !important;
            }

            h2, .content {
                color: rgba(var(--white), 1) !important;
            }
        }

        .inner {
            @include breakpoint(sm) {
                padding-bottom: 4rem;
            }

            .content {
                @include breakpoint(lg-down) {
                    padding-left: 0;
                }
            }
        }

        .header-image {
            height: 40rem;
            background: $gradient-light;

            position: absolute;
            top: 0;
            right: 0;

            @include breakpoint(sm-down) {
                height: 100%;
                border-radius: 0 0 0.625rem 0.625rem;

                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: rgba(var(--accent), 0.8);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            @include breakpoint(sm) {
                border-radius: 1rem 0 0 1rem;
            }

            @include breakpoint(md) {
                border-radius: 2rem 0 0 2rem;
            }

            @include breakpoint(lg) {
                border-radius: 2rem;
            }

            img {
                height: 100%;
                width: auto;
                object-fit: cover;
            }
        }
    }

    &.landing {
        @include breakpoint(sm) {
            padding-top: 2.5rem;
        }

        .info {
            padding-top: 8rem;
            // padding-bottom: 1.5rem;

            @include breakpoint(sm) {
                padding-top: 8rem;
                padding-bottom: 4rem;
            }
        }

        .inner {
            @include breakpoint(sm) {
                padding-bottom: 3.25rem;
            }
        }

        .header-image {
            height: 100%;
            border-radius: 1rem 0 0 1rem;
            background: $gradient-light;

            position: absolute;
            top: 0;
            right: 0;

            @include breakpoint(sm) {
                width: calc(100% - 4rem);
            }

            @include breakpoint(md) {
                width: calc(100% - 6rem);
                border-radius: 2rem 0 0 2rem;
            }

            @include breakpoint(lg) {
                width: calc(100% - 10rem);
                border-radius: 2rem;
            }


            &.has-image {
                background: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}