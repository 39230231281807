.search {
	&-fixture {
		pointer-events: none;
		z-index: 99;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(var(--dark), 0);
		transition: $transition-fast;

		@include breakpoint(sm-down) {
			padding: 0 1rem;
		}

		&.active {
			pointer-events: all;
			background: rgba(var(--dark), 0.2);

			.search {
				&-wrapper {
					pointer-events: all;
				}
			}
		}
	}

	&-wrapper {
		opacity: 0;
		pointer-events: none;
	}

	&-container {
	    border-radius: 0.625rem;
	    box-sizing: border-box;
		background: rgba(var(--white), 1);
		box-shadow: $shadow;
	}

	&-input {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		input {
			padding-left: 1rem;
			border-radius: 0.625rem 0.625rem 0 0;

			@include breakpoint(sm) {
				padding-left: 2rem;
			}
		}

		.icon {
			right: 0;
			position: absolute;
			cursor: pointer;
			color: rgba(var(--dark), 1);

			&:hover {
				color: rgba(var(--accent), 1);
			}

			i {
				color: inherit;
				margin: 2rem;
			}
		}
	}

	&-results {
		margin-bottom: 1rem;
		max-height: 60vh;
		overflow: auto;

		li {
			a {
				padding: 0.5rem 1rem;
				background: rgba(var(--white), 1);
				color: rgba(var(--dark), 1);

				@include breakpoint(sm) {
					padding: 0.5rem 2rem;
				}

				&:hover {
					background: rgba(var(--light), 1);
					color: rgba(var(--dark), 1);
				}

				&.active {
					background: rgba(var(--accent), 1);
					color: rgba(var(--white), 1);
				}

				.icon {
					display: none;
					padding: 1rem;
					position: absolute;
					left: 0;
				}

				span {
					&.title {
						font-size: 1.25rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}
}