.point-list {
	@include breakpoint(sm-down) {
		.col {
			article {
				margin-bottom: 1.5rem;
			}

			&:last-child {
				article {
					margin-bottom: 0;
				}
			}
		}
	}

	article {
		box-sizing: border-box;

		@include breakpoint(lg) {
			padding-right: 2.5rem;
		}
	}

	h3, p {
		margin-bottom: 0.5rem;

		@include breakpoint(sm) {
			margin-bottom: 0.75rem;
		}
	}
}