.content-section {

	._offset {
		@include breakpoint(sm) {
			width: calc(100% + 3.5rem);
			margin-left: -3.5rem;
		}
	}

	._intro {
		& + .content {
			margin-top: 1rem;
			
			@include breakpoint(sm) {
				margin-top: 4rem;
			}
		}
	}

	.content {
		box-sizing: border-box;
		// padding-left: 3.5rem;

		img {
			display: inline-flex;
			border-radius: 1rem;

			&.full-width {
				width: calc(100% + 7rem);
				max-width: unset;
				margin: 2rem 0;
				margin-left: -3.5rem;
			}
		}
	}
}