.services {
	&-top {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

		@include breakpoint(sm) {
	        padding-left: 1.5rem;
	        padding-right: 1.5rem;
		}
	}

	&-wrapper {
		background: rgba(var(--white), 1);
		z-index: 999;
		overflow-x: clip; 
		overflow-y: visible;
	}

	&-list {
		padding-top: 1rem;

		@include breakpoint(sm) {
			padding-top: 2.5rem;
		}

		li {
			margin-bottom: 0.25rem;
			cursor: pointer;
			transition: 0ms;

			&._flappie {
				--size: 0rem;
				height: 100%;
				background: $gradient-light;
				color: rgba(var(--dark), 1);

				&:after {
					transition: 0ms;
					opacity: 0;
				}
			}

			&:hover {
				background: $gradient-white;
			}

			.inner {
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;
				padding: 1.75rem 0;

				@include breakpoint(sm-down) {
					padding-left: 1rem;
					padding-right: 1rem;
				}

				h3 {
					display: flex;
				}

				p {
					margin: 0;
				}

				.content {
					@include breakpoint(sm) {
						padding-left: 1.5rem;
					}
				}

				.more {
					display: none;

					.button {
						margin-bottom: -1rem;
					}
				}
			}

			&.active {
				--size: 4rem;
				background: $gradient;
				transition: $transition-fast;
				transition-property: --size, clip-path !important;

				&:after {
					opacity: 1;
					transition: $transition-fast;
				}

				h3 {
					margin: 0 0.5rem 0.75rem 0;
				}

				.content {
					color: rgba(var(--white), 1);

					@include breakpoint(sm) {
						padding-left: 1rem;
					}
				}

				.more {
					display: flex;
				}

				.toggle {
					display: none;
				}

				._new {
					box-shadow: inset 0 0 0 1px rgba(var(--white), 0.24);
					color: rgba(var(--white), 1);
				}
			}
		}
	}

	&-image {
		width: 100%;
		position: relative;
		pointer-events: none;

        @include breakpoint(sm-down) {
        	margin: -2rem 0;
        }

		img {
			width: 100%;
			height: auto;

			&.bg {
				opacity: 0;
				position: relative;
			}

			&.anim {
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;

				&.tuut {
					position: absolute;
					top: 6%;
					left: 24%;
				}
			}
		}

		.bus {
			width: 100%;
			height: auto;
			opacity: 0;
			position: absolute;
			top: 6%;
			left: 24%;
			z-index: 99;

			img {
				width: 100%;
				height: auto;
				position: absolute;
				top: 0;
				right: 0;
				opacity: 1;
			}
		}
	}
}