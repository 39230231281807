.progress-fixture {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    color: rgba(var(--white), 1);
    transition: $transition-fast;

    &.paused {
        .bi {
            transform: scale(1);
            opacity: 1;
        }
    }

    .bi {
        opacity: 0;
        position: absolute;
        font-weight: bold;
        transform: scale(0);
        transform-origin: center center;
        transition: $transition-faster;
    }
}