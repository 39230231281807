// Define variables

:root {
	--accent:  231,   0,  37;

	--teal:      0, 118, 130;
	--glow:      0, 240, 181;
	--darkred: 175,  21,   0;

	--red:     208,   0,   3;
	--orange:  255, 153,   0;
	--green:    36, 173,  12;

	--blue:     11,  41, 120;
	--yellow:  255, 168,   0;
	--purple:  152, 110, 226;
	--gold:    174, 159,  52;

	--white:   255, 255, 255;
	--light:   246, 246, 246;
	--medium:  144, 144, 144;
	--dark:      0,  39,  43;

	color: rgba(var(--dark), 1);

	._dark {
		--dark:     255, 255, 255;
		--medium:   246, 246, 246;
		--light:    144, 144, 144;
		--white:      0,  39,  43;
		
		color: rgba(var(--dark), 1);
	}
}


// COLS
$grid__cols: 12;
$decoradius: 1.5rem;
$bp: 4rem;
$sp: 2.5rem;

$shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.08), 0 0.5rem 1rem rgba(0, 0, 0, 0.08);

$transition-fastest: 64ms ease;
$transition-faster: 128ms cubic-bezier(0.22, 1, 0.36, 1);
$transition-fast: 240ms cubic-bezier(0.22, 1, 0.36, 1);
$transition-regular: 400ms cubic-bezier(0.22, 1, 0.36, 1);
$transition-slow: 800ms cubic-bezier(0.22, 1, 0.36, 1);

$gradient-iso: linear-gradient(26deg, rgba(var(--white), 1) 50%,rgba(var(--dark), 1) 50%, rgba(var(--dark), 0) 100%);
$gradient-light: linear-gradient(90deg, rgba(var(--light), 1) 0%, rgba(var(--light), 0) 100%), rgba(var(--accent), 0);
$gradient-white: linear-gradient(90deg, rgba(var(--white), 1) 0%, rgba(var(--white), 0) 100%), rgba(var(--accent), 0);
$gradient: linear-gradient(90deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 153, 0, 0.2) 100%), rgba(var(--accent), 1);
$gradient-before: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(255, 153, 0, 0.2) 100%), rgba(var(--accent), 0);
$gradient-op: linear-gradient(90deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 153, 0, 0.2) 100%), rgba(var(--accent), 0.96);

// Color palette
.accent  { color: rgba(var(--accent), 1) !important }
.teal    { color: rgba(var(  --teal), 1) !important }
.orange  { color: rgba(var(--orange), 1) !important }
.purple  { color: rgba(var(--purple), 1) !important }
.gold    { color: rgba(var(  --gold), 1) !important }
.green   { color: rgba(var( --green), 1) !important }
.white   { color: rgba(var( --white), 1) !important }
.dark    { color: rgba(var(  --dark), 1) !important }
.grey    { color: rgba(var(--medium), 1) !important }
.blue    { color: rgba(var(  --blue), 1) !important }
.red     { color: rgba(var(   --red), 1) !important }