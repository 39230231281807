.pagination {
	&-wrapper {
		width: 100%;
		margin-top: 2.5rem;
	}

	&-numeric {
		padding: 0 0.25rem;

		li {
			@extend .w500;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			height: 100%;
			min-height: 3.5rem;
			min-width: 3.5rem;
			margin: 0 0.25rem;
			padding: 1.125rem 1rem 1.0625rem;

			border-radius: 0.375rem;
			background: rgba(var(--dark), 0);
			line-height: 90%;
			font-size: 1.25rem;
			color: rgba(var(--dark), 0.8);
			cursor: pointer;
			transition: $transition-faster;

			&:hover {
				background: rgba(var(--dark), 0.24);
			}

			&.current {
				background: $gradient;
				color: rgba(var(--white), 1);
			}
		}
	}
}