.logo {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 2;
	height: 3rem;

	@include breakpoint(lg-down) {
		padding: 0 1rem;
	}

	@include breakpoint(sm-down) {
		padding: 0 0.5rem;
	}

	span {
		display: none;
	}

	&-accent {
		fill: rgba(var(--accent), 1);
	}

	&-dark {
		fill: rgba(var(--dark), 1);
	}

	svg {
		display: flex;

		@include breakpoint(sm-down) {
			max-width: 100%;
		}
	}
}