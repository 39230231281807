.cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
    max-width: 88rem;
    margin: 0 auto;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    box-sizing: border-box;

    &.col-2 {
        @include breakpoint(sm-down) {
            .col {
                & + .col {
                    margin-top: 2.5rem;
                }
            }
        }
    }

    &.max {
        max-width: 88rem;
    }

    @include breakpoint (sm) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @include breakpoint (md) {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    &.right {
        flex-direction: row-reverse;

        &-sm {
            @include breakpoint (sm) {
                flex-direction: row-reverse;
            }
        }
    }

    &.col-2 {
        @include breakpoint(sm) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    .col {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        box-sizing: border-box;
        position: relative;
        z-index: 1;

        @include breakpoint(sm-down) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        &s {
            padding-left: 0;
            padding-right: 0;
        }

        &.np {
            padding-left: 0;
            padding-right: 0;

            &-right {
                padding-right: 0;
            }

            &-left {
                padding-right: 0;
            }
        }

        &.sm {
            @for $i from 1 through $grid__cols {
                &-#{$i} {
                    width: calc(100 / ($grid__cols / $i) ) * 1%;
                }
            }
        }
        
        @include breakpoint (sm) {
            &.md {
                @for $i from 1 through $grid__cols {
                    &-#{$i} {
                        width: calc(100 / ($grid__cols / $i) ) * 1%;
                    }
                }

                &-24 {
                    width: calc(100 / (12 / 2.4) ) * 1%;
                }

                &-35 {
                    width: calc(100 / (12 / 3.5) ) * 1%;
                }

                &-55 {
                    width: calc(100 / (12 / 5.5) ) * 1%;
                }
            }
        }
        
        @include breakpoint (md) {
            &.lg {
                @for $i from 1 through $grid__cols {
                    &-#{$i} {
                        width: calc(100 / ($grid__cols / $i) ) * 1%;
                    }
                }

                &-35 {
                    width: calc(100 / (12 / 3.5) ) * 1%;
                }


                &-55 {
                    width: calc(100 / (12 / 5.5) ) * 1%;
                }
            }
        }
        
        @include breakpoint (lg) {
            &.xl {
                @for $i from 1 through $grid__cols {
                    &-#{$i} {
                        width: calc(100 / ($grid__cols / $i) ) * 1%;
                    }
                }
            }
        }
    }
}

.np {
    padding-left: 0 !important;
    padding-right: 0 !important;

    &.sm {
        @include breakpoint (sm) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        &-down {
            @include breakpoint (sm-down) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}

.br {
    border-radius: 0.5rem !important;
}

.p {
    &-y {
        padding-top: $sp;
        padding-bottom: $sp;

        @include breakpoint (sm) {
            padding-top: $bp;
            padding-bottom: $bp;
        }

        &-sm {
            @include breakpoint (sm) {
                padding-top: $bp;
                padding-bottom: $bp;
            }

            &-down {
                @include breakpoint (sm-down) {
                    padding-top: $sp;
                    padding-bottom: $sp;
                }
            }
        }

        &-top {
            padding-top: $sp;

            @include breakpoint (sm) {
                padding-top: $bp;
            }

            &-sm {
                @include breakpoint (sm) {
                    padding-top: $bp;
                }

                &-down {
                    @include breakpoint (sm-down) {
                        padding-top: $sp;
                    }
                }
            }
        }

        &-bottom {
            padding-bottom: $sp;

            @include breakpoint (sm) {
                padding-bottom: $bp;
            }

            &-sm {
                @include breakpoint (sm) {
                    padding-bottom: $bp;
                }

                &-down {
                    @include breakpoint (sm-down) {
                        padding-bottom: $sp;
                    }
                }
            }
        }
    }

    &-x {
        padding-left: 1rem;
        padding-right: 1rem;

        @include breakpoint (sm) {
            padding-left: $bp;
            padding-right: $bp;
        }

        &-left {
            padding-left: 1rem;

            @include breakpoint (sm) {
                padding-left: $bp;
            }

            &-sm {
                @include breakpoint (sm) {
                    padding-left: $bp;
                }
            }
        }

        &-right {
            padding-right: 1rem;

            @include breakpoint (sm) {
                padding-right: $bp;
            }

            &-sm {
                @include breakpoint (sm) {
                    padding-right: $bp;
                }
            }
        }
    }
}

.m {
    &-grid {
        margin-bottom: 1.5rem;
    }

    &-y {
        margin-top: $sp;
        margin-bottom: $sp;

        @include breakpoint (sm) {
            margin-top: $bp;
            margin-bottom: $bp;
        }

        &-top {
            margin-top: $sp;

            @include breakpoint (sm) {
                margin-top: $bp;
            }
        }

        &-bottom {
            margin-bottom: $sp;

            @include breakpoint (sm) {
                margin-bottom: $bp;
            }
        }
    }

    &-x {
        margin-left: 1rem;
        margin-right: 1rem;

        @include breakpoint (sm) {
            margin-left: $bp;
            margin-right: $bp;
        }

        &-left {
            margin-left: 1rem;

            @include breakpoint (sm) {
                margin-left: $bp;
            }
        }

        &-right {
            margin-right: 1rem;

            @include breakpoint (sm) {
                margin-right: $bp;
            }
        }
    }
}

.hide {
    &-sm {
        @include breakpoint (sm) { display: none !important; }

        &-down {
            @include breakpoint (sm-down) { display: none !important; }
        }
    }

    &-md {
        @include breakpoint (md) { display: none !important; }

        &-down {
            @include breakpoint (md-down) { display: none !important; }
        }
    }

    &-lg {
        @include breakpoint (lg) { display: none !important; }

        &-down {
            @include breakpoint (lg-down) { display: none !important; }
        }
    }

    &-xl {
        @include breakpoint (xl) { display: none !important; }

        &-down {
            @include breakpoint (xl-down) { display: none !important; }
        }
    }
}

.rw {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;

    &.even {
        justify-content: space-evenly;
    }

    &.reverse {
        flex-flow: row-reverse wrap;

        &-sm {
            @include breakpoint(sm) {
                flex-flow: row-reverse wrap;                
            }

            &-down {
                @include breakpoint(sm-down) {
                    flex-flow: column-reverse wrap;                
                }
            }
        }
    }

    &.nw {
        flex-wrap: nowrap;
    }

    &.center {
        justify-content: center;
    }

    &.middle {
        align-items: center;
    }

    &.stretch {
        align-items: stretch;
    }

    &.base {
        align-items: baseline;
    }

    &.space {
        justify-content: space-between;

        &-a {
            justify-content: space-around;
        }

        &-e {
            justify-content: space-evenly;
        }
    }

    &.end {
        justify-content: flex-end;
    }

    &.bottom {
        align-items: end;
    }

    &.grow {
        flex-grow: 2;
    }
}

.cl {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 2;
    position: relative;
    text-align: left;

    &.center {
        align-items: center;
    }

    &.start {
        justify-content: flex-start;
        text-align: left;
    }

    &.middle {
        justify-content: center;
    }

    &.space {
        justify-content: space-between;
    }

    &.reverse {
        flex-direction: column-reverse;
    }

    &.stretch {
        justify-content: stretch;
    }

    &.end {
        justify-content: flex-end;
    }

    &.bottom {
        align-items: bottom;
    }

    &.rtl {
        align-items: flex-end;
    }

    &.grow {
        flex-grow: 2;
    }
}

.op {
    @for $i from 1 through 100 {
        &-#{$i} {
            opacity: calc(100 / (100 / $i) ) * 1%;
        }
    }
}

.oh {
    overflow: hidden !important;
}

.nm {
    margin: 0 !important;
}