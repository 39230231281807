.star-rating {
	@include breakpoint(md-down) {
		display: none;
	}

	padding: 1rem 0;

@include breakpoint(lg) {
	padding: 2.5rem 4rem 0;
	box-sizing: border-box;
}

	i.bi {
		--size: 2.5rem;
		margin-right: 1.5rem;
		color: rgba(var(--orange), 1);
	}

	u {
		position: relative;
		text-decoration: none;

		&:after {
			content: '';
			width: 100%;
			height: 0.25rem;
			background: rgba(var(--glow), 1);
			position: absolute;
			top: 100%;
			left: 0;
		}
	}

	p {
		margin: 0;
		line-height: 112%;
	}

	.link {
		padding: 0.75rem;
		margin: 0 0 -0.75rem -0.75rem;
		text-decoration: underline;
		color: rgba(var(--teal), 1);
	}
}