::-moz-selection {
    color: rgba(var(--white), 1);
    background: rgba(var(--teal), 1);
}

::selection {
    color: rgba(var(--white), 1);
    background: rgba(var(--teal), 1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}