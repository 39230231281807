.active-filters {
	margin: -0.125rem;
	padding-bottom: 2.5rem;

	li {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		height: 2.5rem;
		margin: 0.125rem;
		padding: 0 1rem;
		border-radius: 2rem;
		background: rgba(var(--light), 1);
		cursor: pointer;

		position: relative;
		transition: $transition-faster;

		&:hover {
			padding-right: 2.5rem;
			background: rgba(var(--accent), 1);
			color: rgba(var(--white), 1);

			i {
				opacity: 1;
			}
		}

		i {
			opacity: 0;
			position: absolute;
			right: 0.5rem;
			transition: $transition-faster;
		}
	}
}