.vacancy {
	&-item {
		width: 100%;
		height: auto;

		@include breakpoint(sm-down) {
			margin-bottom: 0.25rem;
		}

		@include breakpoint(sm) {
			padding: 1rem 1rem 0;
		}

		._flappie {
			height: 100%;
			width: 100%;
			--size: 0rem;
			background: rgba(var(--light), 1);
			color: rgba(var(--dark), 1);
			transition: $transition-fast;
		}

		.inner {
			display: flex;
			flex-grow: 2;
			width: 100%;
			padding: 1.5rem 1rem 1rem;
			box-sizing: border-box;

			@include breakpoint(sm) {
				padding: 1.75rem 2rem 0.5rem;
			}

			@include breakpoint(sm) {
				padding-right: 14rem;
			}

			p {
				margin: 0 0 0.5rem;
			}
		}

		h2 {
			margin: 0 0 0.5rem;
			font-size: 1.5rem;
			font-family: 'Nomenclatur-Medium';
			letter-spacing: -1px;

			@include breakpoint(sm) {
				font-family: 'Nomenclatur';
				font-size: 2rem;
				letter-spacing: -1.3px;
			}
		}

		.vacancy-details {
			margin: 0.5rem -1rem;

			li {
				padding: 0.5rem 1rem;

				i.bi {
					--size: 2rem;
					margin-right: 0.75rem;
				}

				span {
					&.value {
						font-size: 1.125rem
					}
				}
			}
		}

		.description {
			width: 100%;
		}

		.icon {
			position: absolute;
			top: 1.5rem;
			right: 1.5rem;
			pointer-events: none;
			opacity: 0;
			transition: $transition-fast;
		}

		&:hover,
		&.swiper-slide-active {
			._flappie {
				--size: 2.5rem;
				--flap-color: var(--accent);
				background: $gradient;
				color: rgba(var(--white), 1);

				@include breakpoint(sm) {
					--size: 4rem;
				}
			}

			.icon {
				opacity: 1;
			}
		}

		figure {
			position: absolute;
			width: 14rem;
			height: 100%;
			top: 0;
			right: 0;
			overflow: hidden;

			@include breakpoint(sm-down) {
				display: none;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&.item {
		}
	}
}