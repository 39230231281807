.vacancy {
    &-top {
        box-shadow: 0 0.125rem 0 0 rgba(var(--light), 1);

        .info {
            .content {
                @include breakpoint(sm-down) {
                    padding-bottom: 1rem;
                }
            }
        }
    }

    &-description {
        @include breakpoint(sm-down) {
            padding: 1.5rem 0;
        }

        @include breakpoint(sm) {
            padding: 2.5rem 0;
        }

        @include breakpoint(lg) {
            padding: 4rem 0 4rem 2.5rem;
        }
    }

    &-details {
        width: auto;
        position: relative;

        @include breakpoint(sm-down) {
            margin-bottom: 1rem;
        }

        &.box {
            max-width: 100%;
            padding: 0.5rem;
            margin: 0 0 0.375rem;

            box-sizing: border-box;
            background: rgba(var(--white), 1);
            border-radius: 0.625rem;
            box-shadow: $shadow;

            @include breakpoint(lg) {
                margin-top: -1rem;
                padding: 0.5rem 1rem;
            }
        }

        li {
            padding: 0.5rem 0.75rem;
    
            @include breakpoint(sm) {
                // padding: 1rem 2rem;
            }
    
            @include breakpoint(md) {
                padding: 0.75rem 1rem;
            }
    
            @include breakpoint(lg) {
                padding: 1rem 2rem;
            }

            @include breakpoint(sm-down) {
                width: 50%;

                &.apply {
                    width: 100%;

                    .button {
                        text-align: center;
                        width: 100%;

                        span {
                            width: 100%;
                        }
                    }
                }
            }

            span {
                &.key {
                    opacity: 0.64;
                }

                &.value {
                    font-size: 1.25rem;
                    opacity: 1;
                }
            }

            i.bi {
                margin-right: 1.5rem;
                font-size: 1.75rem;
            }

            &.apply {
                padding: 0.5rem;
                flex-grow: 2;
            }
        }
    }
}