.gallery {
	.swiper {
	    overflow: visible;

	    &-slide {
	        opacity: 0;
	        transition: $transition-fast;

	        &-active {
	            opacity: 1;
	        }
	    }
	}
}