.filters {
	&-fixture {
		@include breakpoint(sm-down) {
			width: 100vw !important;
			height: 80vh;
			background: rgba(var(--white), 1);
			border-radius: 0.625rem;
			box-shadow: 0 0 1rem rgba(var(--dark), 0.12);
			position: fixed !important;
			top: unset;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 9999 !important;

			transform: translateY(100%);
			transition: $transition-faster;

			&.active {
				transform: translateY(0);
			}
		}
	}

	&-header {
		padding: 0 0.25rem;
		box-sizing: border-box;
		background: rgba(var(--white), 1);
		position: sticky;
		top: 0;
		z-index: 100;

		&:before {
			content: '';
			width: 100%;
			height: 0.125rem;
			border-radius: 0.125rem;
			background: rgba(var(--dark), 0.08);

			position: absolute;
			top: 100%;
		}

		.title {
			@extend .w600;
			font-size: 1.125rem;
			font-weight: 500;
		}

		.close {
			padding: 0.625rem;
			cursor: pointer;
		}
	}

	&-wrapper {
		padding: 0;

		@include breakpoint(sm-down) {
			height: 100%;
		}

		@include breakpoint(sm) {
			max-height: 100vh;
			padding: 1rem;
			position: sticky;
			top: 0;
		}

		ul {
			padding-bottom: 8rem;

			@include breakpoint(sm) {
				padding-bottom: 3rem;
			}
		}

		.filter {
			margin: 1.5rem 0 0;

			@include breakpoint(sm) {
				margin: 3rem 0 0;
			}

			h3 {
				margin: 0;
				font-size: 1.25rem
			}

			label {
				// padding-top: 0.5rem;
				cursor: pointer;

				span {
					font-size: 1.125rem;
				}
			}
		}
	}
}