.placeholder {
	&-header {
		min-height: 7.75rem;
		// background: green;
	}

	&-partner-slider {
		min-height: 11.5rem;
		// background: pink;
	}

	&-project-slider {
		min-height: 36.5rem;
		// background: magenta;
	}

	&-services {
		min-height: 51rem;
		// background: purple;
	}

	&-activities {
		min-height: 18rem;
		// background: darkcyan;
	}

	&-catalog {
		min-height: 100vh;
		// background: red;
	}
}