// Define typography
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css");

@font-face {
  font-family: "Nomenclatur";
  src: url('../../static/fonts/Nomenclatur-Variable.ttf') format("truetype-variations");
  font-weight: 1 999;
}

@font-face {
  font-family: "Nomenclatur-Medium";
  src: url('../../static/fonts/Nomenclatur-Medium.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nomenclatur-Bold";
  src: url('../../static/fonts/Nomenclatur-Bold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

i.bi {
    --size: 1.5rem;
    display: inline-block;
    vertical-align: text-bottom;
    width: var(--size);
    height: var(--size);
    font-size: var(--size);

    &:before { 
        vertical-align: 0;
    }
}

:root {
    font-family: "Nomenclatur";
    font-weight: 100;
}

a {
    color: inherit;
}

* {
    font-family: "Nomenclatur";
    font-weight: 600;
    font-size: 1rem;
}

.w500 {
    font-family: 'Nomenclatur-Medium';
    font-weight: normal;
    font-style: normal;
}

.w600 {
    font-family: 'Nomenclatur-Bold';
    font-weight: normal;
    font-style: normal;
}

b {
    font-family: 'Nomenclatur-Bold';
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    line-height: 112%;

    br {
        line-height: 0;
    }

    b {
        @extend .w500;
    }
}

h1 {
    font-size: 6rem;
    letter-spacing: -0.125rem;
}

h2 {
    margin-bottom: 1rem;
    font-size: 2.25rem;
    letter-spacing: -0.125rem;

    @include breakpoint(sm) {
        margin-bottom: 1.5rem;
        font-size: 3rem;
        letter-spacing: -0.125rem;
    }
}

h3 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-family: 'Nomenclatur-Medium';
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.0625rem;

    @include breakpoint(sm) {
        margin-bottom: 0.75rem;
        font-size: 1.75rem;
    }
}

h4 {
    margin-bottom: 0.75rem;
    font-size: 1.625rem;
}

._mid {
    p {
        font-size: 1.25rem;  
    }
}

._small {
    p {
        font-size: 1rem;  
    }
}

._center {
    * {
        text-align: center;
    }
}

p {
    margin-bottom: 1rem;
    line-height: 136%;
    font-size: 1.25rem;

    @include breakpoint (sm) {
        font-size: 1.375rem;
    }

    &._mid {
       font-size: 1.125rem;  
    }

    &._small {
       font-size: 1rem;  
    }

    a {
        display: inline;
        color: rgba(var(--accent), 1);
        background: rgba(var(--accent), 0);
        border-radius: 0.125rem;
        text-decoration: underline;
        position: relative;
        transition: background $transition-faster;

        &:hover {
            text-decoration: none;
            background: rgba(var(--accent), 0.12);
        }
    }
}

._intro {
    font-size: 1.375rem;

    @include breakpoint(sm) {
        font-size: 1.5rem;
    }
}

.content,
._content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: -0.5rem 0 0;

    h2, h3, h4, h5 {
        margin: 0.5rem 0 1.5rem;
    }

    p {
        margin: 0.5rem 0 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;
        margin: 0.5rem 0 0.75rem;
        counter-reset: list;

        li {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0.75rem;
            font-size: 1.25rem;
            line-height: 144%;
            list-style: none;

            &:before {
                content: counter(list);
                counter-increment: list;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 1rem;
                border-radius: 100%;
                transition: $transition-faster;
                background-position: center center;
                background-color: rgba(var(--accent), 1);
                box-shadow: 0 0 0 0.25rem rgba(var(--accent), 0.12);
                font-size: 1rem;
                font-family: 'Nomenclatur-Medium';
                font-weight: normal;
                font-style: normal;
                color: rgba(var(--white), 1);
            }
        }
    }

    ul {
        li {
            &:before {
                content: '';
                background-repeat: no-repeat;
                background-position: bottom center;
                background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3271 3.3102C12.5384 3.10904 12.8196 2.99782 13.1113 3.00003C13.403 3.00224 13.6825 3.1177 13.8907 3.32204C14.099 3.52638 14.2197 3.80362 14.2274 4.09527C14.2351 4.38691 14.1292 4.67015 13.9321 4.8852L7.94706 12.3702C7.84415 12.4811 7.71994 12.57 7.58186 12.6318C7.44378 12.6935 7.29467 12.7268 7.14344 12.7296C6.99221 12.7324 6.84197 12.7046 6.7017 12.6481C6.56143 12.5915 6.43401 12.5072 6.32706 12.4002L2.35806 8.4312C2.24753 8.32821 2.15888 8.20401 2.09739 8.06601C2.0359 7.92801 2.00284 7.77904 2.00018 7.62799C1.99751 7.47693 2.0253 7.32689 2.08188 7.18681C2.13846 7.04673 2.22268 6.91948 2.32951 6.81265C2.43633 6.70582 2.56358 6.6216 2.70367 6.56502C2.84375 6.50844 2.99379 6.48065 3.14485 6.48332C3.2959 6.48598 3.44487 6.51905 3.58287 6.58053C3.72087 6.64202 3.84507 6.73067 3.94806 6.8412L7.08906 9.9807L12.2986 3.3432C12.3079 3.33166 12.3165 3.32064 12.3271 3.3102Z" fill="white"/></svg>');
                background-position: center center;
                background-color: rgba(var(--accent), 1);
            }
        }
    }
}