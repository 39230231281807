.activity-list {
	ul {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		width: calc(100% + 0.5rem);
		margin: 0 -0.25rem;

		@include breakpoint(sm) {
			width: calc(100% + 1rem);
			margin: 0 -0.5rem;
		}

		li {
			flex-shrink: 0;
			width: 50%;
			padding: 0 0.125rem;

			@include breakpoint(sm) {
				width: calc(100% / 3);
				padding: 0 0.375rem;
			}

			@include breakpoint(md) {
				width: 25%;
				padding: 0 0.5rem;
			}
		}

		li {
			margin-bottom: 0.25rem;
			break-inside: avoid-column;

			@include breakpoint(sm) {
				margin-bottom: 0.75rem;
			}

			a {
				box-sizing: border-box;
				position: relative;

				&:hover {
					&:after {
						opacity: 1;
						background: $gradient;
						transform: scale(1);
					}

					.inner {
						color: rgba(var(--white), 1);

						svg {
							color: inherit;
						}
					}
				}

				&:before,
				&:after {
					content: '';
					width: 100%;
					height: 100%;
					border-radius: 0.625rem;
					position: absolute;
				}

				&:before {
					background: rgba(var(--light), 1);
				}

				&:after {
					opacity: 0;
					background: $gradient-before;
					transition: $transition-fastest;
					transform: scale(0.96);
					z-index: 1;
				}

				.inner {
					flex-grow: 2;
					height: 4rem;
					padding: 0 1.5rem;
					border-radius: 0.625rem;
					font-size: 1.125rem;
					font-weight: 800;
					position: relative;
					z-index: 2;

					span {

					}

					svg {
						color: rgba(var(--teal), 1);
					}
				}
			}
		}
	}
}