form.form {
    padding: 1.5rem 0;
}

.input-item {
    all: unset;
    appearance: none;
    width: 100%;
    max-width: 100%;
    min-height: 3.75rem;
    padding: 1.125rem 1rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 0.0625rem rgba(var(--dark), 00);
    background: rgba(var(--light), 1);

    line-height: 100%;
    font-size: 1.25rem;
    color: rgba(var(--dark), 1);

    transition: $transition-fastest;

    &:hover {
        background: rgba(var(--medium), 0.25);
    }

    &:focus {
        background: rgba(var(--light), 0.5);
    }
}

.input-checkbox {
    all: unset;
    flex-shrink: 0;
    appearance: none;
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 1rem;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 0.125rem rgba(var(--teal), 0.64), 0 0 0 0 rgba(var(--teal), 0.12);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3271 3.3102C12.5384 3.10904 12.8196 2.99782 13.1113 3.00003C13.403 3.00224 13.6825 3.1177 13.8907 3.32204C14.099 3.52638 14.2197 3.80362 14.2274 4.09527C14.2351 4.38691 14.1292 4.67015 13.9321 4.8852L7.94706 12.3702C7.84415 12.4811 7.71994 12.57 7.58186 12.6318C7.44378 12.6935 7.29467 12.7268 7.14344 12.7296C6.99221 12.7324 6.84197 12.7046 6.7017 12.6481C6.56143 12.5915 6.43401 12.5072 6.32706 12.4002L2.35806 8.4312C2.24753 8.32821 2.15888 8.20401 2.09739 8.06601C2.0359 7.92801 2.00284 7.77904 2.00018 7.62799C1.99751 7.47693 2.0253 7.32689 2.08188 7.18681C2.13846 7.04673 2.22268 6.91948 2.32951 6.81265C2.43633 6.70582 2.56358 6.6216 2.70367 6.56502C2.84375 6.50844 2.99379 6.48065 3.14485 6.48332C3.2959 6.48598 3.44487 6.51905 3.58287 6.58053C3.72087 6.64202 3.84507 6.73067 3.94806 6.8412L7.08906 9.9807L12.2986 3.3432C12.3079 3.33166 12.3165 3.32064 12.3271 3.3102Z" fill="transparent"/></svg>');
    transition: $transition-faster;

    &.checked,
    &:checked {
        background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3271 3.3102C12.5384 3.10904 12.8196 2.99782 13.1113 3.00003C13.403 3.00224 13.6825 3.1177 13.8907 3.32204C14.099 3.52638 14.2197 3.80362 14.2274 4.09527C14.2351 4.38691 14.1292 4.67015 13.9321 4.8852L7.94706 12.3702C7.84415 12.4811 7.71994 12.57 7.58186 12.6318C7.44378 12.6935 7.29467 12.7268 7.14344 12.7296C6.99221 12.7324 6.84197 12.7046 6.7017 12.6481C6.56143 12.5915 6.43401 12.5072 6.32706 12.4002L2.35806 8.4312C2.24753 8.32821 2.15888 8.20401 2.09739 8.06601C2.0359 7.92801 2.00284 7.77904 2.00018 7.62799C1.99751 7.47693 2.0253 7.32689 2.08188 7.18681C2.13846 7.04673 2.22268 6.91948 2.32951 6.81265C2.43633 6.70582 2.56358 6.6216 2.70367 6.56502C2.84375 6.50844 2.99379 6.48065 3.14485 6.48332C3.2959 6.48598 3.44487 6.51905 3.58287 6.58053C3.72087 6.64202 3.84507 6.73067 3.94806 6.8412L7.08906 9.9807L12.2986 3.3432C12.3079 3.33166 12.3165 3.32064 12.3271 3.3102Z" fill="white"/></svg>');
        background-position: center center;
        background-color: rgba(var(--accent), 1);
        box-shadow: inset 0 0 0 1.25rem rgba(var(--accent), 0);
    }
}

._dark {
    .input-checkbox {
        box-shadow: inset 0 0 0 0.125rem rgba(var(--teal), 0.64), 0 0 0 0 rgba(var(--teal), 0.12);

        &.checked,
        &:checked {
            background-color: rgba(var(--dark), 0.12);
            box-shadow: inset 0 0 0 1.25rem rgba(var(--dark), 0);
        }
    }
}

select {
    cursor: pointer;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
select {
    @extend .input-item;
}

label { // input item
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: text;
    padding-top: 1rem;

    span.label {
        padding: 0.5rem;
        box-shadow: inset 0 0 0 0 rgba(var(--white), 1);
        font-size: 1.25rem;
        color: rgba(var(--dark), 0.64);
        position: absolute;
        top: 1.625rem;
        left: 0.5rem;
        transition: $transition-fastest;
    }

    .arrow,
    .decorator {
        display: none;
        color: rgba(var(--teal), 0.24);
        position: absolute;
        top: 2.375rem;
        right: 1.25rem;
    }

    &.select {
        &:hover {
            .arrow {
                color: rgba(var(--accent), 1);
            }
        }
    }

    input,
    select,
    textarea {
        &:required {
            & ~ .decorator {
                display: flex;
            }
        }

        &:not(:placeholder-shown) + span.label,
        &:focus + span.label {
            font-size: 0.875rem;
            color: rgba(var(--dark), 0.64);
            top: 0.0625rem;
        }
    }

    select {
        & ~ .arrow {
            display: flex;
            color: rgba(var(--teal), 1);
        }
    }

    &.radio,
    &.checkbox {
        display: flex;
        flex-flow: column-reverse nowrap;
        justify-content: flex-start;
        align-items: stretch;

        span.label {
            position: relative;
            top: unset;
            left: unset;
            // font-size: 0.875rem;
            // color: rgba(var(--dark), 0.64);
            // top: 0.0625rem;
        }

        div {
            width: 100%;

            label {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding-left: 0.5rem;
                cursor: pointer;

                line-height: 100%;
                font-size: 1.25rem;
                color: rgba(var(--dark), 1);

                input {
                    @extend .input-checkbox;
                }

                &:hover {
                    input {
                        box-shadow: inset 0 0 0 0.125rem rgba(var(--teal), 0), 0 0 0 0.25rem rgba(var(--accent), 0.12);

                        &:not(:checked) {
                            box-shadow: inset 0 0 0 0.125rem rgba(var(--teal), 1), 0 0 0 0.25rem rgba(var(--teal), 0.12);
                        }
                    }
                }
            }
        }
    }

    &.radio {
        div label input {
            border-radius: 100%;
        }
    }
}

form { // Form template
    ul { // django fixture
        margin: 0 -0.25rem;

        li {
            padding: 0.25rem;
        }
    }

    .submit-form {
        margin-top: 1.5rem;
    }
}