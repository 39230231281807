.button {
	@extend .w500;

	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	max-height: 3.5rem;

	padding: 1.125rem 1rem 1.0625rem;
	border-radius: 0.375rem;

	line-height: 90%;
	font-size: 1.125rem;
	color: rgba(var(--dark), 1);

	position: relative;

	@include breakpoint(sm) {
		font-size: 1.25rem;
	}

    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    &:before {
        z-index: 1;
    }

    &s { // Button wrap
    	.button {
    		& + .button {
    			margin-left: 1rem;

    			@include breakpoint(sm) {
					margin-left: 2rem;
				}
    		}

    		&.small,
    		&.menu {
    			& + .button.small,
    			& + .button.menu {
					margin: 0;
    			}
    		}
    	}
    }

	span {
		white-space: nowrap;
		line-height: inherit;
		font-size: inherit;
		color: inherit;

		& + i.bi,
		& + svg {
			margin-left: 0.5rem;
		}
	}

	i.bi,
	svg {
		& + span {
			margin-left: 0.5rem;
		}
	}

	&._mid {
		height: 3rem;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 1.125rem;
	    font-family: "Nomenclatur";
	    font-weight: 100;

	    i.bi {
	    	--size: 1.125rem;
	    }
	}

	&._small {
		height: 2.5rem;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 1rem;
	    font-family: "Nomenclatur";

	    i.bi {
	    	--size: 1rem;
	    }
	}

	&.menu {
		height: 3rem;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		font-size: 1rem;

		i.bi {
			@include breakpoint(sm-down) {
				--size: 1.75rem;
			}
		}

		&.icon {
			transition: $transition-faster;

			&:hover {
				color: rgba(var(--teal), 1);
			}
		}
	}

	&.primary {
		background: $gradient;
		color: rgba(var(--white), 1);
		transition: $transition-faster;

		&.reverse {
			background: rgba(var(--white), 1);
			color: rgba(var(--dark), 1);
		}

		&.white {
			span {
				color: rgba(var(--accent), 1) !important;
			}
		}

		&:hover {
			box-shadow: 0 0.25rem 0.75rem rgba(var(--accent), 0.2);
		}
	}

	&.secondary {
		padding-left: 0;
		padding-right: 0;
		color: rgba(var(--dark), 1);
		position: relative;

		&:after {
			width: 100%;
			height: 0.125rem;
			color: rgba(var(--teal), 1);
			background: currentColor;
			opacity: 0.24;
			top: unset;
			bottom: 0;
		}

		svg {
			color: rgba(var(--teal), 1);
		}

		&.reverse {
			color: rgba(var(--dark), 1);

			&:after,
			svg {
				color: inherit !important;
			}
		}
	}

	&.text {
		padding-left: 0;
		padding-right: 0;

		&:after {
			width: 100%;
			height: 0.125rem;
			opacity: 0;
			background: rgba(var(--accent), 0.24);
			top: unset;
			bottom: 0;
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}

	&.disabled {
		pointer-events: none;
		cursor: unset;
		opacity: 0;
		// background: rgba(var(--light), 1);
	}

	&.filters {
		justify-content: center;
		position: fixed;
		left: 0.25rem;
		right: 0.25rem;
		bottom: 0.25rem;
		z-index: 99999;

		&.scroll-stop {
			opacity: 0;
		}

		.count {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;

			width: 2rem;
			height: 2rem;
			margin-left: 1rem;
			border-radius: 100%;
			background: rgba(var(--white), 1);
			color: rgba(var(--accent), 1);

			// position: absolute;
			// right: 1rem;
		}
	}
}

.dark {
	.button {
		&.secondary {
			&:after,
			svg {
				color: rgba(var(--glow), 1);
			}
		}
	}
}