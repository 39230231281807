.footer {
	height: auto;
	background: rgba(var(--white), 1);
	color: rgba(var(--dark), 1);
	position: relative;

	&-main {
		position: relative;

		&:after {
			@include breakpoint(sm) {
				content: '';
				width: 100vw;
				height: 0.125rem;
				background: rgba(var(--teal), 0.12);
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
	}

	&-info {
		padding-right: 0 !important;
		position: relative;

		&:after {
			@include breakpoint(sm) {
				content: '';
				width: 0.125rem;
				height: 100%;
				background: rgba(var(--teal), 0.12);
				position: absolute;
				top: 0;
				left: 100%;
			}
		}
	}

	&-top {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 0;

		@include breakpoint(sm) {
			padding: 3rem 4rem 3rem 0;
		}

		.logo {
			display: flex;

			@include breakpoint(sm) {
				margin-left: -1rem;
			}

			svg {
				width: auto;
				height: 4rem;
			}

			&-accent,
			&-dark {
				fill: rgba(var(--dark), 1);
			}
		}

		.star-rating {
			padding: 0;

			.link {
				display: none;
			}
		}
	}

	&-menus {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;

		padding: 1.5rem 0 0;
		box-sizing: border-box;
		position: relative;

		@include breakpoint(sm) {
			padding: 4rem 2rem 4rem 0;
		}

		&:before {
			content: '';
			width: 100vw;
			height: 0.125rem;
			background: rgba(var(--teal), 0.12);
			position: absolute;
			top: 0;
			right: 0;
		}

		.footer-menu {
			width: 50%;
			padding-bottom: 1.5rem;

			@include breakpoint(sm) {
				width: 30%;
				padding-bottom: 0;
			}

			h3 {
				@extend .w500;
				margin-bottom: 1.25rem;
				font-size: 1rem;
				letter-spacing: 0;
			}

			ul {
				width: 100%;
				margin: -0.25rem -1rem;

				&:hover {
					li {
						a {
							color: rgba(var(--dark), 0.64);

							&:hover {
								color: rgba(var(--dark), 1);
							}
						}
					}
				}

				li {
					width: 100%;

					&:hover {
						a {
							color: rgba(var(--dark), 1)
						}
					}

					a {
						padding: 0.25rem 1rem;
						color: rgba(var(--dark), 0.8);
						transition: $transition-faster;
					}
				}
			}
		}
	}

	&-about {
		&:after {
			@include breakpoint(sm-down) {
				content: '';
				width: 100vw;
				height: 0.125rem;
				background: rgba(var(--teal), 0.12);
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		.content {
			padding: 2.5rem 0 1.5rem;
			box-sizing: border-box;

			@include breakpoint(sm) {
				padding: 4rem 2rem 4rem;
			}

			h3 {
				@extend .w500;
				// font-size: 1.75rem;
				// letter-spacing: -0.4px;
			}

			p {
				color: rgba(var(--dark), 0.64);
			}
		}
	}

	&-bottom {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;

		@include breakpoint(sm) {
			padding-top: 4rem;
			padding-bottom: 4rem;
		}

		.col {
			&.sm-12 {
				@include breakpoint(sm-down) {
					display: flex;
					flex-flow: column nowrap;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.content {
			padding: 0 1rem;
		}

		span {
			font-size: 0.9375rem;
			opacity: 0.64;
			transition: $transition-fast;
		}

		a {
			padding: 1rem;

			@include breakpoint(sm) {
				margin: -1rem 0 0;
			}

			&:hover {
				span {
					opacity: 1;
				}
			}
		}
	}
}