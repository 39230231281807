.tags {
	margin-left: -0.25rem;
	position: relative;

	&.fadeout {
		// overflow: hidden;

		// Fadeout
		@for $i from 3 through 10 {
			li:nth-child(3n + #{$i}) {
				opacity: 1 - $i * 0.1;
			}
		}

		// li {
		// 	&:nth-child(1),
		// 	&:nth-child(2) {
		// 		opacity: 1;
		// 	}
		// }

		// &:after {
		// 	--bg: var(--light);
		// 	content: '';
		// 	width: 4rem;
		// 	height: 100%;
		// 	background: linear-gradient(to right, rgba(var(--bg), 0) 0%, rgba(var(--bg), 1) 100%);
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// 	transition-duration: 480ms;
		// 	transition-timing-function: ease-out;
		// 	transition-property: --size, clip-path, background;
		// }
	}

	li {
		padding: 0.375rem 0.75rem;
		margin: 0.125rem;
		white-space: nowrap;
		font-size: 1rem;
		line-height: 100%;
		box-sizing: border-box;
		position: relative;

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			box-shadow: 0 0 0 0.0625rem currentColor;
			opacity: 0.24;
			border-radius: 1rem;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}