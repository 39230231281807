.projects-slider {
	padding-left: 2.5rem; // -0.5rem
	padding-right: 2.5rem; // -0.5rem	

	.swiper {
		overflow: visible;

		&-wrapper {
			display: flex;
			flex-flow: row nowrap;
			align-items: stretch;

			&:hover {
				.swiper-slide-active {
					._flappie {
						height: 100%;
						--size: 0rem;
						background: rgba(var(--light), 1);
						color: rgba(var(--dark), 1);
						transition: $transition-fast;
					}

					.icon {
						opacity: 0;
					}

					.tags {
						&.fadeout {
							&:after {
								--bg: var(--light);
								transition: 160ms ease;
							}
						}
					}
				}

				.swiper-slide {
					&:hover {
						._flappie {
							--size: 4rem;
							--flap-color: var(--accent);
							background: $gradient;
							color: rgba(var(--white), 1);
						}

						.icon {
							opacity: 1;
						}

						.tags {
							&.fadeout {
								&:after {
									--bg: var(--accent);
									transition: 160ms ease;
								}
							}
						}
					}
				}
			}
		}

		&-slide {
			@extend .project-item;
			max-width: 100%;
			margin-bottom: 0 !important;
			padding: 0 0.5rem;
		}
	}

	&-wrapper {

	}
}