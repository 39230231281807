.complex-gallery {
	.swiper {
	    overflow: visible;

        @include breakpoint(sm-down) {
	    	width: calc(100vw + 0.5rem);
	    	margin-left: -0.5rem;
	    }

	    &-slide {
	    	padding: 0 0.5rem;
	        transition: $transition-fast;

	        @include breakpoint(sm) {
		    	width: auto !important;
		    	padding: 0 5rem;
	        }

	        &-active {
	            .progress-fixture {
	            	opacity: 1;
	            }
	        }
	    }
	}

	.swiper-pagination {
		
	}

	.progress-fixture {
		top: 0;
		right: 0;
		position: absolute;
		z-index: 100;
		opacity: 0;
		z-index: 10;

		@include breakpoint(sm) {
			right: 5rem;
		}
	}
}