.project {
	&-item {
		width: auto;
		height: auto;

		@include breakpoint(sm-down) {
			margin-bottom: 0.5rem;
		}

		@include breakpoint(sm) {
			max-width: 27.5rem;
		}

		._flappie {
			height: 100%;
			width: 100%;
			--size: 0rem;
			background: rgba(var(--light), 1);
			color: rgba(var(--dark), 1);
			// transition: $transition-fast;
		}

		.inner {
			display: flex;
			flex-grow: 2;
			width: 100%;
			padding: 1.5rem 1rem 1rem;
			box-sizing: border-box;

			position: relative;

			@include breakpoint(sm) {
				padding: 1.75rem 2rem 1.25rem;
			}

			.cl, .rw {
				width: 100%;
			}
		}

		h2 {
			margin: 0;
			font-size: 1.625rem;
			letter-spacing: -1.3px;

			@include breakpoint(sm) {
				font-size: 2rem;
			}
		}

		address {
			margin-bottom: 1rem;
			padding: 0.5rem 0;

			span {
				font-size: 1rem;

				& + span {
					margin-left: 0.75rem;
					opacity: 0.64;
				}
			}
		}

		figure {
			width: 100%;

			img {
				width: 100%;
				height: auto;
			}
		}

		.icon {
			position: absolute;
			bottom: 1rem;
			right: 1.5rem;
			pointer-events: none;
			opacity: 0;
			z-index: 10;
			// transition: $transition-fast;

			border-radius: 1.5rem;
			background: rgba(var(--accent), 1);
		}

		&:hover,
		&.swiper-slide-active {
			._flappie {
				--size: 4rem;
				--flap-color: var(--accent);
				background: $gradient;
				color: rgba(var(--white), 1);
			}

			.icon {
				opacity: 1;
			}

			.fadeout {
				&:after {
					background: linear-gradient(to right, rgba(var(--accent), 0) 0%, rgba(var(--accent), 1) 100%);
				}
			}

			// .tags {
			// 	&.fadeout {
			// 		&:after {
			// 			--bg: var(--accent);
			// 		}
			// 	}
			// }
		}

		&.item {
		}
	}
}