.catalog {
	@include breakpoint(sm) {
		width: calc(100% + 2rem);
		margin: 0 -1rem;
	}

	.project-item {
		max-width: unset;

		@include breakpoint(sm) {
			width: calc(100% / 3);
			width: 50%;
			padding: 1rem 1rem 2rem;
		}
	}

	.inner {
		width: 100%;
	}
}

.no-results {
	width: 100%;
	padding: 4rem 1rem;
	box-sizing: border-box;
	text-align: center;

	@include breakpoint(sm) {
		max-width: 40rem;
		padding: 4rem;
	}

	.icon {
		--size: 2.5rem;
		margin-bottom: 1rem;
	}
}