.header {
	width: 100%;

	&-container {
		width: 100%;
	}

	&-wrapper {
		display: flex;
	}
}