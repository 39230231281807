.language {
	&-switch {
		cursor: pointer;
		position: relative;

		@include breakpoint(sm) {
			margin-left: 1rem;
		}

		figure.flag {
			display: flex;
			vertical-align: text-bottom;
			border-radius: 0.1875rem;
			overflow: hidden;
		}

		a {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;

			.flag {
				// margin-right: 0.5rem;
			}
		}

		ul {
			position: absolute;
			top: 0;
			left: -0.25rem;
			padding: 0.25rem;
			border-radius: 0.5rem;
			box-shadow: $shadow;
			background: rgba(var(--white), 1);

			a {
				padding: 0.75rem 1rem;
				background: rgba(var(--white), 1);
				border-radius: 0.25rem;
				transition: $transition-fastest;

				&:hover {
					background: rgba(var(--dark), 0.12);
				}
			}

			span {
				position: absolute;
				opacity: 0;
			}
		}
	}

	&-current {
		// padding: 1rem;
		// margin: -1rem -1rem -1rem 1rem;
		span {
			display: none;
		}

		.flag {
			margin: 0.75rem;

			@include breakpoint(sm) {
				margin: 1rem;
			}
		}
	}
}