.breadcrumbs {
	&-wrapper {
		width: 100%;
		margin-bottom: 2rem;
	}

	&-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: center;

		span {
			white-space: nowrap;
			text-overflow: ellipsis;
			position: relative;

			&:after {
		        content: '';
		        width: 100%;
		        height: 100%;
		        pointer-events: none;
		        position: absolute;
		        top: 100%;
		        left: 0;
		        z-index: 0;

				width: 100%;
				height: 0.125rem;
				color: inherit;
				border-radius: 0.125rem;
				background: $gradient;
			}
		}

		li {
			&.trail { // Mobile uses backbutton instead of a breadcrumb trail
				@include breakpoint(sm-down) {
					margin-left: -1rem;

					a {
						padding: 1rem;

						svg {
							margin-right: 0.5rem;
						}

						span {
						    font-family: 'Nomenclatur-Medium';
						    font-weight: normal;
						    font-style: normal;
							opacity: 1;
							font-size: 1.125rem;
						}
					}
				}
			}
			
			a {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;
				color: rgba(var(--dark), 1);

				span {
					opacity: 0.8;

					&:after {
						opacity: 0;
					}
				}

				svg {
					@include breakpoint(sm) {
						width: 0.75rem;
						height: 0.75rem;
						margin: 0 0.75rem;
						color: inherit;
					}
				}

				&:hover {
					span {
						opacity: 1;

						&:after {
							opacity: 1;
						}
					}
				}
			}

			span {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;

				padding: 0.125rem 0;
				font-size: 1.125rem;
				font-weight: 500;
			}

			&.active {
				span {
				    font-family: 'Nomenclatur-Medium';
				    font-weight: normal;
				    font-style: normal;
				}
			}
		}
	}
}

._flappie {
	.breadcrumbs-container span::after {
		background: currentColor;
	}
}