.c-slider {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: flex-end;

	.swiper {
		width: 10rem;
		margin: 0;
		overflow: visible;

	    &-wrapper {
			-webkit-transition-timing-function:linear!important; 
			-o-transition-timing-function:linear!important;
			transition-timing-function:linear!important; 
	    }

	    &-slide {
	    	// width: auto !important;
	    }
	}
}