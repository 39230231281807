.socials {
	margin: -0.5rem;

	@include breakpoint(sm-down) {
		padding: 1.5rem 0;
	}

	&:hover {
		a {
			opacity: 0.4;

			&:hover {
				opacity: 1;
			}
		}
	}

	li {
		a {
			display: flex;
			margin: 0;
			padding: 0;
			transition: opacity $transition-faster;

			svg {
				width: 2rem;
				height: auto;
				margin: 1rem 0.5rem;
			}
		}
	}
}