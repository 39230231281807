.methods {
	&-fixture {
	}

	&-wrapper {
		.pin-spacer {
			.methods {
				&-fixture {
					flex-flow: row nowrap !important
				}
			}
		}


		// slider styles
		.swiper {
			max-width: 100%;
			overflow: visible;

			&-slide {
				max-width: 27.5rem;
			}
		}
	}

	&-item {
		display: flex;
		flex-shrink: 0;
		box-sizing: border-box;
		box-shadow: -0.125rem 0 0 0 rgba(var(--dark), .08);
		overflow: hidden;

		figure {
			display: flex;
			flex-shrink: 2;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			margin: 2.5rem 0;
			position: relative;

			img {
				max-width: unset;
				height: 100%;
				max-height: 14rem;
				width: auto;
				// position: absolute;
				// height: 100%;
				// width: auto;
				// position: absolute;
				// top: 0;
				// left: 0;
			}
		}

		.label {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			width: 4rem;
			height: 4rem;
			// margin: 0 1.5rem;
			border-radius: 100%;
			background: rgba(var(--accent), 1);
			font-size: 2rem;
			font-weight: 400;
			color: rgba(var(--white), 1);
			// position: absolute;
			// top: 0;
			// left: 0;
			z-index: 10;
		}

		.title {
			margin-bottom: 1rem;

			h2, h3, h4, h5 {
				margin: 0;
			}
		}

		.content {
			p {

			}
		}
	}

	&-step {
		width: 20rem;
		height: 20rem;
		border-radius: 100%;
		margin-bottom: 2rem;
		background: rgba(var(--accent), 1);
	}
}