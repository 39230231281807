._rel {
	position: relative !important;
}

._abs {
	position: absolute !important;
}

._new {
	height: 1.75rem;
	border-radius: 1rem;
	font-size: 1rem;

	text-transform: uppercase;
	letter-spacing: -1px;
	box-shadow: inset 0 0 0 1px rgba(var(--accent), 0.12);
	color: rgba(var(--accent), 1);

	span {
		padding: 0.125rem 0.75rem 0;
		color: inherit;
	}
}

._gradient {
	background: $gradient;
}

._timer {
	display: none;

	// TODO
	width: 2rem;
	height: 2rem;
	border-radius: 1rem;
	border-image: conic-gradient(rgba(var(--white), 0.12) 44%, 0, green ) border-box;
	border-width: 10px;
	border-style: solid;
}

._iso {
	width: 100%;
	padding: 100% 0 0;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
	background: linear-gradient(-190deg, rgba(var(--teal), 0) 40%, rgba(var(--teal), 0.08) 100%);
	position: absolute;
	bottom: -25%;
	pointer-events: none;
}

._head {
	width: 100%;
	padding: 100% 0 0;
	background: linear-gradient(to top, rgba(var(--teal), 0) 75%, rgba(var(--teal), 0.08) 100%);
	clip-path: polygon(0 0, 50% 0, 0 25%);
	bottom: unset;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;

	@include breakpoint(sm-down) {
		clip-path: polygon(0 0, 75% 0, 0 37.5%);
	}
}

._flappie {
	--size: 4rem;
	--flap-color: var(--darkred);
	background: $gradient-op;
	border-radius: 0.5rem;
	color: rgba(var(--white), 1);
	clip-path: polygon(100% 0, 100% calc(100% - var(--size)), calc(100% - var(--size)) 100%, 0 100%, 0 0);
	position: relative;
	transition-duration: 480ms;
	transition-timing-function: ease-out;
	transition-property: --size, clip-path, background;
	backdrop-filter: blur(1rem);

	&:after {
		content: '';
		width: var(--size);
		height: var(--size);
		border-radius: 0.625rem 0 0 0;
		background: rgba(var(--flap-color), 1);
		position: absolute;
		right: 0;
		bottom: 0;
		transition-duration: inherit;
		transition-timing-function: inherit;
	}

	&.active,
	&:hover {
		--size: 5rem;
		transition-duration: 160ms;
	}

	&.tear {
		--size: 20rem;
		transition-duration: 1s;
	}
}